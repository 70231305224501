html {
  --transition-all: all 400ms ease-in-out;
  --bg-color: #708faf;
  --text-color: #000;
  --footer-color: #708faf00;
  --post-container-bg: #fff;
  --nav-bg: #708faf;
  --posts-border: 1px #0000003f solid;

  --loading-anim-1: #e9e9e9;
  --loading-anim-2: #ebebeb;
  --loading-anim-3: #efefef;
  --loading-anim-4: #f2f2f2;
}

.dark {
  --transition-all: all 400ms ease-in-out;
  --bg-color: #111111;
  --text-color: rgb(201, 201, 201);
  --footer-color: #11111100;
  --post-container-bg: rgb(8, 8, 8);
  --post-container-bg1: rgb(0, 0, 0);
  --nav-bg: #111;
  --posts-border: 1px #ffffff04 solid;

  --loading-anim-1: #111111;
  --loading-anim-2: #131313;
  --loading-anim-3: #151515;
  --loading-anim-4: #171717;
}

body {
  margin: 0;
  padding: 0;
  padding-bottom: 120px;
  font-family: Georgia, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-color, #fff);
  color: var(--text-color, #000);
  transition: var(--transition-all);
  overflow-x: hidden;
}

body a {
  color: var(--text-color, #000);
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.nav {
  /* position:fixed;
  top:0;
  left:0;
  right:0; */
  position: relative;
  z-index: 500;
  margin-left: 10px;
  padding: 10px 0px 10px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  /* border-bottom:1px #000 solid; */
}

.nav-mode-btn-menu-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-mode-btn-menu-container div {
  margin: 0 10px;
}

.page-newsletter-btn {
  cursor: pointer;
  border: none;
  padding: 3px 10px;
  border-radius: 5px;
  font-weight: bold;
  background: linear-gradient(
    90deg,
    rgba(63, 94, 251, 1) 0%,
    rgba(252, 70, 107, 1) 100%
  );
  color: #fff;
  font-size: 13px;
  transition: all 1000ms ease;
}

.form-error {
  margin-top: 25px;
  margin-bottom: -20px;
  text-align: center;
}

.page-newsletter-btn:hover {
  transition: all 1000ms ease;
  background: linear-gradient(
    10deg,
    rgba(63, 94, 251, 1) 0%,
    rgba(252, 70, 107, 1) 100%
  );
}

.nav-menu-overlay {
  position: fixed;
  top: 0;
  left: -10px;
  height: 100vh;
  width: 100vw;
  z-index: 7;
  background-color: #00000000;
  transition: var(--transition-all);
  pointer-events: none;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: -10px;
  height: 100%;
  width: 100vw;
  z-index: 7;
  background-color: #00000000;
  transition: var(--transition-all);
  pointer-events: none;
}

.nav-menu-items {
  position: fixed;
  top: 0;
  right: -10px;
  bottom: 0;
  background-color: var(--nav-bg, #708faf);
  padding-top: 50px;
  z-index: 8;
  width: 220px;
  display: flex;
  flex-direction: column;
  transition: var(--transition-all);
  transform: translateX(220px);
}

.nav-open {
  transform: translateX(0px);
}

.nav-overlay-open {
  transition: all 200ms;
  background-color: #000000d2;
  pointer-events: initial;
}

.nav-menu-items li {
  width: 100%;
  padding: 15px 0;
  font-size: 24px;
  /* font-weight:800; */
  list-style: none;
  color: #fff;
  /* background-color:#ff0000; */
}

.nav-menu-items li:hover {
  color: rgb(32, 40, 110);
}

.page-category {
  text-align: center;
  font-size: 32px;
  line-height: 30px;
  margin-bottom: 10px;
  color: #fff;
}

.page-category-description {
  padding: 0 10px;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 120px;
}

.logo-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 50px;
  height: 30px;
}

.logo-container img {
  margin-top: 5px;
  width: 40px;
  /* padding: 10px; */
  /* background-color: #fff; */
}

.page-title {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  /* font-family: "Grenze Gotisch", Georgia, Times, "Times New Roman", serif; */
  /* font-family: "Pacifico", cursive; */
  /* font-family: "Passion One", cursive; */
  font-family: "Permanent Marker", Georgia, Times, "Times New Roman", serif;
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 2px;
  color: #fff;
  text-shadow: 0px 0px 14px rgba(0, 0, 0, 0.589);
}

.mode-container {
  /* background-color: blue; */
  background-image: url("../img/moon12.svg");
  /* padding-top: 10px; */
  /* filter: drop-shadow(1px 1px 2px black); */
  background-size: cover;
  height: 25px;
  width: 25px;
  border-left: 10px;
  cursor: pointer;
  letter-spacing: -1px;
  transition: var(--transition-all);
  /* writing-mode: vertical-lr;
  text-orientation: upright; */
  word-wrap: break-word;
  font-size: 11px;
  font-weight: bold;
}

.hamburger-container {
  /* border: 2px #000 solid; */
  height: 30px;
  width: 30px;
  z-index: 9;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hamburger-container > div {
  transition: all 200ms ease-in-out;
  height: 3px;
  width: 30px;
  /* border: 1px #000 solid;  */
  background-color: var(--text-color, #000);
  margin: 2px 0;
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}

.hamburger-open {
  transition: all 200ms ease-in-out;
  transform: rotate(45deg);
}

.container {
  width: 100%;
  position: relative;
  margin: 0 auto;
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 250px));
  justify-content: center;
}

.post-container {
  position: relative;
  padding: 10px;
  margin: 7px;
  overflow: hidden;
  background-color: var(--post-container-bg, #fff);
  /* background-color: #ffffff00; */
  z-index: 1;
}

.post-container:hover {
  transition: scale 1000ms ease;
  transform: scale(1.02);
}

.post-container-series {
  /* width: 200px; */
  overflow: visible;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.432);
  margin: 10px 10px;
  box-shadow:
    /* The top layer shadow */ 0 1px 1px rgba(0, 0, 0, 0.15),
    /* The second layer */ 0 10px 0 -5px var(--post-container-bg, #fff),
    /* The second layer shadow */ 0 10px 1px -4px rgba(0, 0, 0, 0.37);
}
.post-container-series img {
  /* transform: rotate(1.5deg); */
}

.post-container-series:before,
.post-container-series:after {
  content: "";
  height: 98%;
  position: absolute;
  width: 100%;
  z-index: -1;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.945);
}
.post-container-series:before {
  background: var(--post-container-bg, #fff);
  left: -5px;
  top: 4px;
  transform: rotate(-1deg);
}
.post-container-series:after {
  background: var(--post-container-bg, #fff);
  right: -3px;
  top: 1px;
  transform: rotate(0deg);
}

@media only screen and (min-width: 600px) {
  .container {
    width: 90%;
  }

  .post-container {
    min-height: 340px;
    max-height: 380px;
    /* min-height: 300px; */
  }
}

.read-time-container {
  font-size: 15px;
  color: #808080;
  margin: -10px 0;
}

.feature-image {
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.heading {
  margin-bottom: -12px;
}

.excerpt-container {
  font-size: 16px;
  font-weight: 100;
  font-style: none;
}

.post-image {
  position: relative;
  width: 100%;
  margin: 0 auto;
  /* background-color:red; */
}

.banner-container {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  min-width: 100vw;
  height: 200px;
  background-color: rgba(141, 141, 141, 0.76);
  background-size: cover;
  background-position: center center;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}

.home-content-container {
  margin-top: 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  background-color: transparent;
}

.home-content-container h3 {
  text-align: center;
  font-size: small;
  font-weight: 100;
  letter-spacing: 1px;
  line-height: 15px;
  padding: 0 10%;
  color: #fff;
}

.home-content-container h2 {
  text-align: center;
  font-size: 25px;
  letter-spacing: 10px;
  color: #fff;
  text-shadow: 0px 0px 14px rgba(0, 0, 0, 0.36);
}

.home-content-container h2:hover {
  color: #405b8b;
  text-shadow: 0px 0px 14px rgba(0, 0, 0, 0);
}

.latest-posts-home {
  width: 75%;
  margin: 0 auto;
  font-size: small;
  margin-bottom: -30px;
  font-weight: 100;
  letter-spacing: 3px;
  color: #fff;
  text-shadow: 0px 0px 14px rgba(0, 0, 0, 0.36);
}

.see-more-tags-btn {
  width: 75%;
  margin: 0 auto;
  font-size: small;
  margin-top: -10px;
  margin-bottom: 0px;
  font-weight: 100;
  letter-spacing: 3px;
  color: rgba(255, 255, 255, 0.589);
  text-shadow: 0px 0px 14px rgba(0, 0, 0, 0.36);
  cursor: pointer;
}

.meta-tags {
  /* background-color: #fff; */
  width: 80%;
  max-width: 200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: solid var(--text-color) 3px;
  border-top: solid var(--text-color) 3px;
  opacity: 1.6;
}

.meta-details {
  display: inline-block;
  /* border: solid black 1px; */
  /* margin: 10px 5px 25px 5px; */
  /* background-color: rgba(96, 105, 128, 0.397); */
  border-radius: 5px;
  font-size: 15px;
  text-align: center;
  padding: 2px 5px;
  /* box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.171); */
}

/* .post-content {
  font-size: 20px;
} */

.post {
  padding: 20px;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  width: 70%;
  margin: 0 auto;
  color: var(--text-color);
  object-fit: cover;
}

.post a {
  text-decoration: underline;
}

.post img {
  min-width: 200px;
  max-height: 50vh;
  /* width: 500px; */
  object-fit: contain;
  display: block;
  margin: 0 auto;
}

.posts-content > span {
  color: blue;
}

@media only screen and (max-width: 800px) {
  .post img {
    width: 100%;
    max-height: 50vh;
    display: block;
    margin: 0 auto;
  }
  .post,
  .tags-wrapper {
    width: calc(100vw - 80px);
    margin: 0 auto;
  }
}

.post h3 {
  font-size: 1rem;
  font-weight: 400;
}

.post h5 {
  font-size: 1.25rem;
}

.post-category {
  margin-bottom: -15px;
  font-size: 0.69em;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 4px;
  color: #304b6b;
  opacity: 0.7;
}

.post-title {
  text-align: center;
  font-size: 2rem;
  font-weight: 900;
  line-height: 30px;
  margin: 20px 0;
  margin-bottom: 20px;
}

.black-banner {
  transition: all 200ms ease;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  padding: 5px 0;
  background-color: #000;
  /* height: 20px; */
  text-align: center;
  width: 100vw;
  color: #fff;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.3);
}

.black-banner-text {
  margin: 0;
  font-size: 23px;
  font-weight: 100;
  padding: 0 5px;
}

.footer-container {
  pointer-events: none;
  width: 100vw;
  /* background: linear-gradient(0deg, var(--bg-color, #fff) 0%, var(--bg-color, #fff) 35%, var(--footer-color, #ffffff00) 100%); */
  background-color: var(--bg-color, #fff);
  position: fixed;
  z-index: 6;
  bottom: 0px;
  left: 0;
  font-weight: 100;
  font-size: 12px;
  text-align: center;
  line-height: 0px;
  /* transition: var(--transition-all); */
}

.loading-bg {
  display: flex;
  height: 80vh;
  width: 100vw;
  justify-content: center;
  align-items: start;
}

.loading-bg div {
  width: 100px;
  height: 100px;
  background-image: url("../img/IMG_1950.GIF");
  background-size: contain;
}

hr {
  color: var(--text-color);
}

.tags-wrapper {
  margin-top: 60px;
  width: 70%;
  margin: 60px auto 0 auto;
  border-radius: 10px;
  padding: 10px;
  /* background-color: rgba(255, 255, 255, 0.11); */
  /* opacity: 0.95; */
}

.tag-page-heading {
  text-align: center;
  font-size: 30px;
  margin-bottom: -15px;
}

.post-tags-container {
  margin-top: 15px;
  padding: 10px 0;
  width: 100%;
  /* border: #00000050 1px solid;
  background-color: rgba(61, 69, 87, 0.397);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.247); */
}

.tags-heading {
  margin: 0px;
  font-weight: bold;
  font-size: 13px;
  opacity: 0.8;
  letter-spacing: 10px;
  text-transform: uppercase;
  /* font-style: italic; */
  margin-bottom: -10px;
}

.tags {
  /* border: 1px #000 solid; */
  /* display: grid; */
  width: 100%;
  text-align: center;
  font-size: 14px;
  position: relative;
  letter-spacing: 3px;
  display: flex;
  flex-wrap: wrap;
  grid-template-columns: repeat(auto-fit, minmax(40px, 100px));
  align-items: center;
  /* word-break: break-all; */
  word-wrap: normal;
  grid-gap: 5px;
  justify-content: start;
}

.tags a {
  text-decoration: none;
}

@media only screen and (max-width: 600px) {
  .tags {
    /* grid-template-columns: 1fr 1fr; */
  }
}

.tag {
  /* padding: 5px 5px; */
  /* width: 95px; */
  color: #000;
  background-color: rgb(99, 133, 163);
  padding: 5px;
  position: relative;
  margin: 5px;
  border-radius: 20px;
  text-decoration: none;
  font-size: 10px;
}

.tag:hover {
  /* border: #00000050 1px solid; */
  /* text-decoration: underline; */
  background-color: rgb(115, 151, 180);
  transform: scale(1.1);
}

.page-title-loading {
  /* text-align: center; */
  position: absolute;
  text-decoration: none;
  top: 0;
  left: 0;
  right: 0;
  margin-top: calc(50vh - 30px);
  font-size: 32px;
  color: var(--text-color, #000);
}

.page-description-loading {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-top: calc(50vh + 10px);
  font-size: 16px;
  color: var(--text-color, #000);
}

.dark-mode-container {
  background: url("../img/sun123.svg");
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
}

.anim {
  animation-name: arrow;
  animation-duration: 700ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  border: 1px solid #fff;
}

@keyframes arrow {
  0% {
    background: linear-gradient(
      90deg,
      #506d8d 25%,
      #608e9e 50%,
      #819fbf 75%,
      #92afcf 100%
    );
  }
  33% {
    background: linear-gradient(
      90deg,
      #92afcf 25%,
      #506d8d 50%,
      #608e9e 75%,
      #819fbf 100%
    );
  }
  66% {
    background: linear-gradient(
      90deg,
      #819fbf 25%,
      #92afcf 50%,
      #506d8d 75%,
      #608e9e 100%
    );
  }
  100% {
    background: linear-gradient(
      90deg,
      #608e9e 25%,
      #819fbf 50%,
      #92afcf 75%,
      #506d8d 100%
    );
  }
}

.bio-pic {
  width: 200px;
  filter: drop-shadow(16px 16px 20px rgba(0, 0, 0, 0.253));
}

/* ----------------------------- */

.newsletter-form-wrapper {
  transition: var(--transition-all);
  pointer-events: none;
  position: fixed;
  z-index: -10;
  top: 0;
  left: -10px;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.newsletter-form-container {
  pointer-events: initial;
  position: relative;
  z-index: 8;
  border-radius: 7px;
  background: linear-gradient(
    -90deg,
    rgba(63, 94, 251, 1) 0%,
    rgba(252, 70, 107, 1) 100%
  );
  width: 80%;
  max-width: 500px;
  margin: 0 auto;
  transform: scale(0);
  color: #000;
}

.newsletter-form-heading {
  text-align: center;
  margin-bottom: -20px;
}

.newsletter-form-active {
  transition: all 200ms ease-out;
  transform: scale(1);
}

.newsletter-form-container form {
  width: 90%;
  margin: 0 auto;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
}

.newsletter-form-container form input {
  position: relative;
  margin: 5px 0;
  border-radius: 5px;
  height: 20px;
  border: none;
  padding: 5px;
}

.newsletter-form-container form input {
  margin: 5px 0;
}

.newsletter-form-container form label {
  margin: 5px 0;
  font-size: 14px;
  margin-bottom: 0px;
  margin-top: 10px;
  letter-spacing: 2px;
  font-weight: 500;
}

.newsletter-submit-btn {
  border: none;
  border: 2px solid rgba(0, 0, 0, 0.336);
  margin: 20px auto 0px auto;
  padding: 0px 15px;
  background-color: rgba(51, 90, 163, 0.226);
  color: #fff;
  border-radius: 50px;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 20px;
  font-weight: 100;
  box-shadow: inset 0px 0px 20px 3px rgba(0, 0, 0, 0.5);
  /* font-family: Georgia, Times, "Times New Roman", serif; */
  font-family: "Verdana", "Grenze Gotisch", Georgia, Times, "Times New Roman",
    serif;
  /* font-family: "Pacifico", cursive; */
  /* font-family: "Passion One", cursive; */
}

.newsletter-submit-btn:hover {
  background-color: rgba(51, 90, 163, 0.479);
  color: rgb(255, 255, 255);
  border: 2px solid rgba(0, 0, 0, 0.582);
}

.newsletter-close-btn {
  /* pointer-events: none; */
  cursor: pointer;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: #fff;
  text-align: right;
  line-height: 0;
  position: absolute;
  margin-top: -20px;
  margin-left: 10px;
  font-size: 25px;
}

.form-loading-container {
  display: flex;
  justify-content: center;
  margin-left: -20px;
  width: 80%;
  margin-left: 0 auto;
}

.form-loading-container img {
  object-fit: cover;
  margin: 30px 0;
  margin-left: 60px;
}

.socials-container {
  width: 68px;
  /* background-color: #0000008c; */
  color: var(--text-color);
  text-align: center;
  margin: 0px auto;
  margin-top: 30px;
  padding: 5px 10px;
  display: grid;
  border-radius: 10px;
  grid-template-columns: repeat(4, 30px);
  grid-gap: 10px;
  /* box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.5); */
}
.socials-fb,
.socials-i,
.socials-t,
.socials-sc {
  opacity: 0.75;
}
.socials-fb:hover,
.socials-i:hover,
.socials-t:hover,
.socials-sc:hover {
  color: rgb(47, 63, 99);
}

/* ------------------------------------- */

@media only screen and (max-width: 600px) {
  .page-title {
    font-size: 18px;
  }
  .nav-mode-btn-menu-container div {
    margin-left: 0px;
  }
}

.font-settings-container {
  display: flex;
  position: fixed;
  width: 80px;
  right: 0;
  bottom: 40vh;
  padding: 0;
  transform: translateX(58px);
  transition: var(--transition-all);
}

@media only screen and (max-width: 600px) {
  .font-settings-container {
    bottom: 120px;
  }
}

.font-size-container {
  transition: var(--transition-all);
  width: 22px;
  background-color: rgb(45, 79, 192);
  cursor: pointer;
}

.font-size-container h5 {
  writing-mode: vertical-lr;
  text-orientation: upright;
  margin: 0;
  font-size: 14px;
  padding: 3px;
  color: aliceblue;
}

.font-btns-container {
  align-self: center;
  display: flex;
  justify-content: center;
}

.font-down,
.font-up {
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.589);
  background-color: rgba(255, 255, 255, 0.699);

  /* HIDE HIGHLIGHTING */
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
}

.font-up {
  margin: 5px;
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 1500px;
  font-size: 26px;
  text-align: center;
  width: 35px;
  height: 35px;
}
.font-down {
  text-align: center;
  margin: 5px;
  padding: 5px;
  border-radius: 1500px;
  font-size: 20px;
  width: 30px;
  height: 30px;
}

.font-btns-close {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  margin: 0;
  font-size: 22px;
  text-align: center;
  cursor: pointer;
}

.font-panel-open {
  transition: var(--transition-all);
  transform: translateX(0px);
}

.font-size-label-off {
  transition: var(--transition-all);
  opacity: 0;
  pointer-events: none;
}

.loading-post-container {
  height: 300px;
  width: 220px;
  margin-left: 8px;
  margin-top: 8px;
}
.loading-post-container {
  background-color: var(--post-container-bg);
  padding: 5px;
}

.loading-post-image {
  height: 150px;
}

.loading-post-ert,
.loading-post-category,
.loading-post-title {
  height: 15px;
  border-radius: 100px;
}

.loading-post-excerpt {
  height: 60px;
  border-radius: 6px;
}

.loading-post-image,
.loading-post-ert,
.loading-post-category,
.loading-post-title,
.loading-post-excerpt {
  margin: 6px 0;
  animation-name: post-loading-anim;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  width: 90%;
  margin: 6px auto;
}

@keyframes post-loading-anim {
  0% {
    background: linear-gradient(
      90deg,
      var(--loading-anim-1) 25%,
      var(--loading-anim-2) 50%,
      var(--loading-anim-3) 75%,
      var(--loading-anim-4) 100%
    );
  }
  33% {
    background: linear-gradient(
      90deg,
      var(--loading-anim-4) 25%,
      var(--loading-anim-1) 50%,
      var(--loading-anim-2) 75%,
      var(--loading-anim-3) 100%
    );
  }
  66% {
    background: linear-gradient(
      90deg,
      var(--loading-anim-3) 25%,
      var(--loading-anim-4) 50%,
      var(--loading-anim-1) 75%,
      var(--loading-anim-2) 100%
    );
  }
  100% {
    background: linear-gradient(
      90deg,
      var(--loading-anim-2) 25%,
      var(--loading-anim-3) 50%,
      var(--loading-anim-4) 75%,
      var(--loading-anim-1) 100%
    );
  }
}
/* @keyframes post-loading-anim {
  0% {
    background: linear-gradient(
      90deg,
      #e9e9e9 25%,
      #ebebeb 50%,
      #efefef 75%,
      #f2f2f2 100%
    );
  }
  33% {
    background: linear-gradient(
      90deg,
      #f2f2f2 25%,
      #e9e9e9 50%,
      #ebebeb 75%,
      #efefef 100%
    );
  }
  66% {
    background: linear-gradient(
      90deg,
      #efefef 25%,
      #f2f2f2 50%,
      #e9e9e9 75%,
      #ebebeb 100%
    );
  }
  100% {
    background: linear-gradient(
      90deg,
      #ebebeb 25%,
      #efefef 50%,
      #f2f2f2 75%,
      #e9e9e9 100%
    );
  }
} */
